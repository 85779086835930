body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media screen and (max-width: 1200px) {
  .grid {
    gap: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .section {
    padding-block: 50px;
  }

  .section__title {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 576px) {
}
